<template>
  <div
    class="voucher"
    :class="{ 'voucher--active': isApply }"
  >
    <v-row no-gutters v-if="!isApply"
    @click="apply(item.code)">
      <v-col cols="5">
        <div class="voucher__box voucher__border">
          <icons icon="virtu" />
        </div>
      </v-col>
      <v-col cols="7">
        <div class="voucher__box">
          <heading-price
            :text="discount"
            color="main"
            position="left"
            bold
            class="mb-1"
          />
          <p class="desc">{{ desc }}</p>
          <p class="valid">{{ valid }} <br> {{dateValid}}</p>
          
        </div>
      </v-col>
    </v-row>
     <v-row no-gutters v-else
    @click="unApply(item.code)">
      <v-col cols="5">
        <div class="voucher__box voucher__border">
          <icons icon="virtu" />
        </div>
      </v-col>
      <v-col cols="7">
        <div class="voucher__box">
          <heading-price
            :text="discount"
            color="main"
            position="left"
            bold
            class="mb-1"
          />
          <p class="desc">{{ desc }}</p>
          <p class="valid">{{ valid }} <br> {{dateValid}}</p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HeadingPrice from "@/components/Heading/HeadingPrice.vue";
import Icons from "@/components/elements/Icons.vue";
export default {
  components: {
    HeadingPrice,
    Icons,
  },
  props: {
    discount: {
      type: String,
      default: "-",
    },
    desc: {
      type: String,
      default: "-",
    },
    valid: {
      type: String,
      default: "-",
    },
    dateValid: {
      type: String,
      default: "-",
    },
    active: {
      type: Boolean,
      default: false,
    },
     item: {
      type: Object,
      default: () => {},
    },
    isApply: {
      type: Boolean,
      default: false,
    },
  },
   methods: {
    apply(id) {
      this.$emit("apply", id);
    },
    unApply(id) {
      this.$emit("unApply", id);
    }
  },
};
</script>

<style lang="scss" scoped>
.voucher {
  display: flex;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  padding: 10px 18px;
  position: relative;
  background: #fbfbfb;
  overflow: hidden;
  border-radius: 8px !important;
  cursor: pointer;
  &--active {
    background: #eeeded;
  }
  &__box {
    flex-direction: column;
    padding: 0 12px;
    display: flex;
    height: 100%;
    .valid {
      font-weight: 500;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: #ff0000;
      margin-bottom: 0;
    }
    .desc {
      color: #000;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 10px;
    }
  }
  &__border {
    justify-content: center;
    align-items: center;
    border-right: 2px dashed rgba(102, 102, 102, 0.2);
  }
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    width: 28px;
    height: 24px;
    background-color: #fff;
    border-radius: 100%;
    box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.25);
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
    width: 28px;
    height: 26px;
    background-color: #fff;
    border-radius: 100%;
    box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.25);
  }
}
</style>
